import { store } from "../redux/reducers/rootReducer";

// Obtener los datos del estado de Redux
const state = store.getState();
const business = state.globalData.business;
const rol = state.globalData.rol;
const userName = state.globalData.userName;

const URL_API = "https://api.onmodoapp.com";

export const handleRequest = async (endpoint, method, values, formId) => {
  try {
    const formData = new FormData();
    for (const key in values) {
      if (Array.isArray(values[key])) {
        formData.append(key, JSON.stringify(values[key]));
      } else if (key === "firmaDoc") {
        formData.append("firmaDoc", values[key]); // Puedes ajustar el índice según sea necesario
      } else {
        formData.append(key, values[key]);
      }
    }

    // Agregar otras propiedades como businessName, rol, nombre, etc., al FormData
    formData.append("businessName", business);
    formData.append("rol", rol);
    formData.append("nombre", userName);

    const url = `${URL_API}/api/${endpoint}`;
    const response = await fetch(url, {
      method: method,
      body: formData,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error", error);
    throw error;
  }
};

/// PARA BORRAR
export const deleteForm = async (idForm, form) => {
  try {
    const response = await fetch(`${URL_API}/api/${form}/${idForm}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error", error);
    throw error;
  }
};

// estado y permisos de edicion
export const sendEditApplication = async ({ values, formId, form }) => {
  form = form === "controlalergenos" ? "dietasespeciales" : form;
  try {
    const response = await fetch(`${URL_API}/api/${form}/${formId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error", error);
    throw error;
  }
};
