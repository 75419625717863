import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import globalDataReducer from "./globalDataReducers";
import { RESET_STORE } from "../actions/globalDataActions";

const persistConfig = {
  key: "root",
  storage,
};

const appReducer = combineReducers({
  globalData: globalDataReducer,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    state = undefined;
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  applyMiddleware(/* middleware opcional */)
);
export const persistor = persistStore(store);
