import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import styles from "./ModalEdicionInfo.module.css";
import { useSelector } from "react-redux";

Modal.setAppElement("#root");

const ModalEdicionInfo = ({ openModal, setOpenModal, form }) => {
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const globalData = useSelector((state) => state.globalData);
  var userName = globalData.userName;
  useEffect(() => {
    userName = globalData.userName;
  }, [globalData]);

  return (
    <>
      {form && (
        <div>
          <Modal
            isOpen={openModal}
            onRequestClose={handleCloseModal}
            className={styles.modal}
            overlayClassName={styles.overlay}
          >
            {form.status === "denied" ? (
              <h2 className={styles.title}>Solicitud Denegada</h2>
            ) : (
              <h2 className={styles.title}>Solicitud Aprobada</h2>
            )}
            <div>
              <b>Solicitante: </b>{" "}
              <span className={styles.textMotivo}>{form.user || userName}</span>
            </div>

            <div>
              <b>Comentario del solicitante: </b>{" "}
              <span className={styles.textMotivo}>"{form.motivoPeticion}"</span>
            </div>

            <div>
              <b>Referente: </b>{" "}
              <span className={styles.textMotivo}>{form.whoApproved}</span>
            </div>
            <div>
              <b>Comentario del referente: </b>{" "}
              <span className={styles.textMotivo}>"{form.motivo}"</span>
            </div>

            <form>
              <div className={styles.contBtn}>
                <button
                  id={styles.btnCancelar}
                  onClick={handleCloseModal}
                  type="button"
                >
                  Cerrar
                </button>
              </div>
            </form>
          </Modal>
        </div>
      )}
    </>
  );
};

export default ModalEdicionInfo;
