export const PUESTOS_N1 = [
    'Cadete',
    'Porteria',
    'Frutero',
    'Portero',
    'Peones',
    'Peones generales',
    'Toilletero',
    'Transporte',
    'Comisionista',
    'Lavacopas',
    'Guardarropista',
    'Ascensorista de servicios',
    'Groom',
    'Montaplato de cocina',
    'Ascensorista',
    'Cadete',
    'Porteria',
    'Bagajista',
    'Sereno vigilador',
    'Mensajero',
    '1/2 oficial auxiliar de recepción',
    'Foguista',
    'Encerador de pisos',
    'Mozo mostrador',
    'Auxiliar recibidor de mercadería',
    'Engrasador',
    'Centrifugador',
    'Estuferas',
    'Jardinero',
    'Cobrador',
    'Empaquetador',
    'Repartidor',
    'Delivery',
    'Auxiliar administrativ',
    'Ayudantes de Panadero de cocina, de barman y de fiambrero',
    'Capataz de peones',
    'Gambucero',
    'Cafetero',
    'Comis',
    'Comis de vinos y de comedor de niños',
    'Empleado Administrativo',
    'Recibidor de mercaderia',
    'Planchadora',
    'Lencera',
    'Lavandera',
    'Capataz de peones generales',
    'Mozo de personal',
    'Mozo de mostrador de atención al cliente-',
    'Medio oficial',
    'Panadero',
    'Mucamas',
    'Valet portero',
    'Telefonista',
    'Encargado depósito inventario',
    'Oficial de oficios varios',
    'Chofer y/o garajista',
    'Bodeguero',
    'Capataz comedor de Administración',
    'Cocktelero',
    'Planchadora a mano',
    'Sandwichero y minutero - Comis de cocina',
    'Oficial panadero',
    'Jefe de teléfonista',
    'Cuenta corrintista',
    'Cajero comedor',
    'Ayudante contador',
    'Adicionista comedor',
    'Capataz',
    'Encargado de sección',
    'Fichero',
    'Guardavida',
    'Empleado principal administrativo',
    'Comis de suit',
    'Fiambre despacho',
    'Cajero adicionista',
    'Portero',
    'Fiambrero o sandwichero principal',
    'Cajero y/o fichero',
    'Adicionista',
    'Empleado principal tecnico especialista (Disc Jokey – iluminación – sonido)-',
    'Jefe de partida',
    'Cocinero',
    'Mozo de salón y de vinos',
    'Camareras/os',
    'Gobernanta',
    'Conserje principal',
    'Empleado principal administrativo',
    'Recepcionista',
    'Barman',
    'Mozo de piso',
    'Maitre de niños',
    'Postrero',
    'Cheff de fila',
    'Jefe de compras y ventas',
    'Rotisero',
    'Maestro de pala pizzero',
    'Maestro Facturero',
    'Maestro Pastelero',
    'Masajista',
    'Capataz o encargado',
    'Parrillero',
    'Capataz de sala',
    'Mozos comedor de niños'
  ]
  
  export const PUESTOS_N2 = [
    'Medio oficial',
    'Panadero',
    'Mucamas',
    'Valet portero',
    'Telefonista',
    'Encargado depósito inventario',
    'Oficial de oficios varios',
    'Chofer y/o garajista',
    'Bodeguero',
    'Capataz comedor de Administración',
    'Cocktelero',
    'Planchadora a mano',
    'Sandwichero y minutero',
    'Comis de cocina',
    'Oficial panadero',
    'Jefe de teléfonista',
    'Cuenta corrintista',
    'Cajero comedor',
    'Ayudante contador',
    'Adicionista comedor',
    'Capataz',
    'Encargado de sección',
    'Fichero',
    'Guardavida',
    'Empleado principal administrativo',
    'Comis de suit',
    'Fiambre despacho',
    'Cajero adicionista',
    'Portero',
    'Fiambrero o sandwichero principal',
    'Cajero y/o fichero',
    'Adicionista',
    'Empleado principal tecnico especialista (Disc Jokey – iluminación – sonido)-',
    'Jefe de partida',
    'Cocinero',
    'Mozo de salón y de vinos',
    'Camareras/os',
    'Gobernanta',
    'Conserje principal',
    'Empleado principal administrativo',
    'Recepcionista',
    'Barman',
    'Mozo de piso',
    'Maitre de niños',
    'Postrero',
    'Cheff de fila',
    'Jefe de compras y ventas',
    'Rotisero',
    'Maestro de pala pizzero',
    'Maestro Facturero',
    'Maestro Pastelero',
    'Masajista',
    'Capataz o encargado',
    'Parrillero',
    'Capataz de sala',
    'Mozos comedor de niños',
    'Jefe de brigada',
    'Gobernanta principal',
    'Maitre principal',
    'Jefe de Conserjeria',
    'Conserje principal',
    'Jefe de recepción',
    'Jefe Técnico especial de oficio'
  ]
  