// globalDataActions.js
export const UPDATE_GLOBAL_DATA = "UPDATE_GLOBAL_DATA";
export const RESET_STORE = "RESET_STORE";

export const updateGlobalData = (newData) => ({
  type: UPDATE_GLOBAL_DATA,
  payload: newData,
});

export const resetStore = () => ({
  type: RESET_STORE,
});
