import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import "./index.css";
import Login from "./components/login/Login";
import Header from "./components/Header/Header";
import AlergenosComida from "./components/forms/AlergenosComida";
import ConstanciaEntrega from "./components/forms/ConstanciaEntrega";
import EntregaBidonesAceiteUsado from "./components/forms/EntregaBidonesAceiteUsado";
import FlashReporteIncidente from "./components/forms/FlashReporteIncidente";
import InformeInternoAccidente from "./components/forms/InformeInternoAccidente";
import RegistroCapacitacion from "./components/forms/RegistroCapacitación";
import RegistroDeDecomiso from "./components/forms/RegistroDeDecomiso";
import RegistroSimulacro from "./components/forms/RegistroSimulacro";
import VerificacionBalanza from "./components/forms/VerificacionBalanza";
import ReporteDeRechazoDevolucionMaterias from "./components/forms/ReporteDeRechazoDevolucionMaterias";
import VerificacionTermometros from "./components/forms/VerificacionTermometros";
import RecoverPassword from "./components/recoverPassword/RecoverPassword";
import Inicio from "./components/inicio/Inicio";
import Cuenta from "./views/Cuentas/cuenta/Cuenta";
import Footer from "./components/footer/Footer";
import FormulariosContainer from "./components/formularios/FormulariosContainer";
import FormulariosCargados from "./components/formulariosCargados/FormulariosCargados";
import FormCargado from "./components/formulariosCargados/formCargado/FormCargado";
import CrearContraseña from "./components/CrearContraseña/CrearContraseña";
import Cuentas from "./views/Cuentas/Cuentas";
import RecordatoriosContainer from "./views/Recordatorios/RecordatoriosContainer";
import SolicitudesDeEdicion from "./views/SolicitudesDeEdicion/SolicitudesDeEdicion";
import Legajos from "./views/Legajos/Legajos";
import Estadisticas from "./views/Estadisticas/Estadisticas";
import InfoBusiness from "./components/InfoBusiness/InfoBusiness";
import { useSelector } from "react-redux";
import FirstOtp from "./views/FirstOtp/FirstOtp";

function App() {
  const location = useLocation();
  const currentLocation = location?.pathname;
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  const [keySearchForms, setKeySearchForms] = useState();
  const [keySearchHistorial, setKeySearchHistorial] = useState();
  const [keySearchLegajo, setKeySearchLegajo] = useState();

  const globalData = useSelector((state) => state.globalData);
  useEffect(() => {
    setIsLoggedIn(!!globalData.idUser);
  }, [globalData]);

  const handleSearchData = (value) => {
    switch (currentLocation) {
      case "/formularios":
        setKeySearchForms(value);
        break;
      case "/formularios-cargados":
        setKeySearchHistorial(value);
        break;
      case "/legajos":
        setKeySearchLegajo(value);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    setKeySearchForms("");
    setKeySearchHistorial("");
    setKeySearchLegajo("");
  }, [currentLocation]);

  return (
    <div className="App">
      <>
        <div
          className="mainContent"
          style={{
            position: "relative",
            height: "fit-content",
            minHeight: "100vh",
          }}
        >
          {currentLocation !== "/inicio-de-sesion" &&
          currentLocation !== "/olvide-contrasena" &&
          currentLocation !== "/restablecer-contrasena" &&
          !currentLocation.includes("/verificacion-usuario") ? (
            <Header search={(value) => handleSearchData(value)} />
          ) : null}

          {/* RUTAS PUBLICAS  */}
          <Routes>
            <Route
              path="/"
              element={
                <Navigate to={isLoggedIn ? "/inicio" : "/inicio-de-sesion"} />
              }
            />
            <Route path="/olvide-contrasena" element={<RecoverPassword />} />
            <Route path="/inicio-de-sesion" element={<Login />} />
            <Route
              path="/restablecer-contrasena"
              element={<CrearContraseña />}
            />

            <Route path="/verificacion-usuario/:email" element={<FirstOtp />} />
          </Routes>
          {isLoggedIn ? (
            <Routes>
              {/* RUTAS PRIVADAS  */}
              <Route path="/inicio" element={<Inicio />} />
              <Route path="/cuentas" element={<Cuentas />} />
              <Route path="/cuenta" element={<Cuenta />} />
              <Route path="/crear-cuenta" element={<Cuenta />} />
              <Route path="/perfil-legajo/:legajo" element={<Cuenta />} />
              <Route
                path="/recordatorios"
                element={<RecordatoriosContainer />}
              />
              <Route
                path="/formularios"
                element={<FormulariosContainer filterByKey={keySearchForms} />}
              />
              <Route
                path="/formularios-cargados"
                element={
                  <FormulariosCargados filterByKey={keySearchHistorial} />
                }
              />
              <Route
                path="/formularios-cargados/:form"
                element={<FormCargado />}
              />
              <Route
                path="/formularios-legajos/:legajo"
                element={<FormCargado />}
              />

              <Route
                path="/solicitudes-edicion"
                element={
                  <SolicitudesDeEdicion filterByKey={keySearchHistorial} />
                }
              />
              <Route
                path="/legajos"
                element={<Legajos filterByKey={keySearchLegajo} />}
              />
              <Route path="/estadisticas" element={<Estadisticas />} />

              {/* Forms */}
              <Route path="/dietas-especiales" element={<AlergenosComida />} />
              <Route path="/ropa-de-trabajo" element={<ConstanciaEntrega />} />
              <Route
                path="/bidones-de-aceite"
                element={<EntregaBidonesAceiteUsado />}
              />
              <Route
                path="/reporte-incidente"
                element={<FlashReporteIncidente />}
              />
              <Route
                path="/informe-accidente"
                element={<InformeInternoAccidente />}
              />
              <Route
                path="/registro-de-capacitacion"
                element={<RegistroCapacitacion />}
              />
              <Route
                path="/registro-decomisos-mp"
                element={<RegistroDeDecomiso />}
              />
              <Route
                path="/registro-simulacro"
                element={<RegistroSimulacro />}
              />
              <Route
                path="/verificacion-balanza"
                element={<VerificacionBalanza />}
              />
              <Route
                path="/verificacion-termometro"
                element={<VerificacionTermometros />}
              />
              <Route
                path="/rechazo-mp"
                element={<ReporteDeRechazoDevolucionMaterias />}
              />

              <Route path="/info-business" element={<InfoBusiness />} />

              <Route path="*" element={<Navigate to="/inicio" />} />
            </Routes>
          ) : currentLocation.includes("verificacion-usuario") ||
            currentLocation === "/restablecer-contrasena" ||
            currentLocation === "/olvide-contrasena" ? null : (
            <Navigate to="/inicio-de-sesion" />
          )}

          {currentLocation !== "/inicio-de-sesion" &&
          currentLocation !== "/inicio-de-sesion" &&
          currentLocation !== "/olvide-contrasena" &&
          currentLocation !== "/restablecer-contrasena" &&
          !currentLocation.includes("/verificacion-usuario") ? (
            <Footer />
          ) : null}
        </div>
        {/* </div> */}
      </>
    </div>
  );
}

export default App;
