import React from "react";
import { useState, useEffect } from "react";
import styles from "./FirstOtp.module.css";
import logo from "../../assets/image/on-modo-grande.png";
import {
  generateOTP,
  restablecerPassword,
  sendOTP,
} from "../../services/Request";
import { useDispatch } from "react-redux";
import { updateGlobalData } from "./../../redux/actions/globalDataActions";
import { useParams, useNavigate } from "react-router-dom";

function FirstOtp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  const [buttonColor, setButtonColor] = useState("#a0b875");
  const [otpValue, setOtpValue] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [showMessageInvalid, setShowMessageInvalid] = useState(false);

  const { email } = useParams();

  const resetForm = () => {
    setOtpValue("");
  };

  const validate = () => {
    let errors = {};
    if (!otpValue || otpValue.trim() === "") {
      errors.otp = "Este campo no puede estar vacío";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleBlur = () => {
    if (errors.otp) {
      setErrors({ ...errors, otp: "" });
    }
  };

  const handleChange = (e) => {
    setOtpValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = validate();
    if (isValid) {
      sendOTP(email, parseInt(otpValue)).then((resp) => {
        if (resp.success && resp.status !== 400) {
          restablecerPassword(email).then((resp) => {
            setShowMessage(true);
            resetForm();
            setTimeout(() => {
              navigate("/restablecer-contrasena");
            }, 7000);
          });
        } else {
          setShowMessageInvalid(true);
        }
      });
    } else {
      alert("Ingrese un código válido.");
    }
  };
  const newOtp = () => {
    generateOTP(email).then((resp) => {
      if (resp.success) {
        resetForm();
        alert("Revise su correo, le hemos enviado un nuevo código");
        setShowMessageInvalid(false);
      }
    });
  };
  useEffect(() => {
    dispatch(
      updateGlobalData({
        email: email,
      })
    );
  }, [email]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {showMessage ? (
          <div className={styles.leftWrapper}>
            <h1 className={styles.title}>Revisá tu correo</h1>
            <div className={styles.parrafoContainer}>
              <p>
                Te hemos enviado un email con el código que necesitarás en el
                siguiente paso.
                <b>No cierres esta pestaña, serás redirigido en 5 segundos.</b>
              </p>
            </div>
          </div>
        ) : (
          <div className={styles.leftWrapper}>
            <h1 className={styles.title}>Verificación de email</h1>
            <div className={styles.parrafoContainer}>
              <p>Te hemos enviado un correo con un código de seguridad.</p>
            </div>
            <form className={styles.formulario} onSubmit={handleSubmit}>
              <div className={styles.inputContainer}>
                <label htmlFor="">Código</label>
                <input
                  className={`${errors.otp && "danger"} ${
                    styles.inputRecovery
                  }`}
                  type="number"
                  placeholder="Código"
                  onChange={handleChange}
                  name="otp"
                  value={otpValue}
                  onBlur={handleBlur}
                />
                {errors.otp && <p className="danger">{errors.otp}</p>}
              </div>

              <div className={styles.buttonContainer}>
                {showMessageInvalid ? (
                  <div>
                    <p className="danger" style={{ textAlign: "center" }}>
                      Código inválido o expirado.{" "}
                      <b style={{ cursor: "pointer" }} onClick={newOtp}>
                        Solicitar uno nuevo.
                      </b>
                    </p>
                  </div>
                ) : null}
                <button
                  className={styles.btn}
                  type="submit"
                  style={{ backgroundColor: buttonColor }}
                >
                  Validar
                </button>
              </div>
            </form>
          </div>
        )}

        <div className={styles.rightWrapper}>
          <img className={styles.logo} src={logo} alt="Logo On Modo" />
        </div>
      </div>
    </div>
  );
}

export default FirstOtp;
