const initialState = {
  rol: "",
  business: "",
  userName: "",
  idChief: "",
  idUser: undefined,
  linkDocumentacion: "",
  imgBusiness: "",
  email: "",
  token: undefined,
};

const globalDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_GLOBAL_DATA":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default globalDataReducer;
